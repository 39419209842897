import React from "react"
import heroStyles from "./hero.module.css"

export default ({ children }) => (
<section className={`hero is-large ${heroStyles.heroImg}`}>
    <div class="hero-body">
        <div class="container">
        {children}
        </div>	
    </div>
</section>
)
