import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


import Hero from "../components/homepageHero"
import Contact from "../components/contact"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const pageTitle = "Home"

const IndexPage = () => (
		<Layout>
		<SEO title={pageTitle} />
		
		

		<Hero>
		<h1 class="title has-text-light">
		Rijbewijs Academy
		</h1>
		<h2 class="subtitle has-text-white">
		Apeldoorn
		</h2>
		</Hero>


		{/* Introduction */}
		<section class="section has-background-neutral-7">
		<div class="container">
		<h1 class="title">Rijbewijs Academy</h1>

<div class="columns is-centered is-vcentered">
<div class="column">

<p>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur suscipit nunc non nunc dapibus, at congue neque cursus. Praesent fermentum ipsum justo, convallis pretium ante sagittis et. Nullam euismod tristique neque, vel auctor arcu mollis at.
</p>
</div>
<div class="column">
<a class="button is-orange-3 is-large" href="#contact">Neem contact op</a>
</div>

</div>
</div>
</section>

<section class="section has-background-neutral-9">
<div class="container">
<div class="columns is-centered">

<div class="column has-text-centered">
<span class="icon">
<FontAwesomeIcon icon="check-circle" color="green" size="3x" />
</span>
<h3 class="title is-4">Betaal in termijnen!</h3>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>

<div class="column has-text-centered">
<span class="icon">
<FontAwesomeIcon icon="check-circle" color="green" size="3x" />
</span>
<h3 class="title is-4">Ben je nog student?</h3>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>

<div class="column has-text-centered">
<span class="icon">
<FontAwesomeIcon icon="check-circle" color="green" size="3x" />
</span>
<h3 class="title is-4">Lessen over?</h3>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>

</div>
</div>
</section>

</Layout>
)

export default IndexPage
